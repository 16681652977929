<template>
  <div class="container">
    <div style="align-content: center; text-align: center; margin: auto; clear: both;">
      <Header :screenSize="getScreenSize()"></Header>
      <Navigation :selection="selection"></Navigation>
      <b-navbar toggleable="lg" type="dark" variant="light" v-bind:style="{backgroundImage: 'url(' + require('../assets/Navbar.jpg') + ')' }">
      <b-navbar-brand href="#"></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item><button type="button" class="btn btn-light" @click="updateSelection('videos')">Home</button></b-nav-item>
          <b-nav-item><button type="button" class="btn btn-light" @click="updateSelection('videos')">Videos</button></b-nav-item>
          <b-nav-item><button type="button" class="btn btn-light" @click="selection = 'about'">About</button></b-nav-item>
          <!-- <form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="hosted_button_id" value="SC327Y8AUZEN4" />
            <input  class="donate-button" type="image" src="https://pics.paypal.com/00/s/YzYwNTdmNWItZTRmOS00ZDRkLWI4ZDEtYjlmNzUyMjk0MDkz/file.PNG" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
          </form> -->
          <!-- <b-nav-item href="#">Link</b-nav-item>
          <b-nav-item href="#" disabled>Disabled</b-nav-item> -->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <b-form-input size="sm" class="mr-sm-2" v-model="videoSearch" placeholder="Search Videos"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
          </b-nav-form>


          <!-- 
          <b-nav-item-dropdown text="Lang" right>
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">ES</b-dropdown-item>
            <b-dropdown-item href="#">RU</b-dropdown-item>
            <b-dropdown-item href="#">FA</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right>

            <template #button-content>
              <em>User</em>
            </template>
            <b-dropdown-item href="#">Profile</b-dropdown-item>
            <b-dropdown-item href="#">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown> 
          -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="container">
      <div class="social-media-container">
           Subscribe:
           <a href="https://www.truthsocial.com/@nabsta"><img class="social-media-icon" src="../assets/TruthSocialT.png"></a>
           <a href="https://rumble.com/c/c-1991912"><img class="social-media-icon" src="../assets/Rumble.png"></a>
           <a href="https://www.bitchute.com/channel/58uJfJvuZCeY/"><img class="social-media-icon" src="../assets/BitChuteLogo.png"></a>
      </div>
    </div>
    <img src="../assets/Bar.jpg" class="divider-bar">
    <Videos v-if="selection=='videos'" order="1" :screenSize="getScreenSize()" :videoSearch="videoSearch" :selectedVideo="selectedVideo" @video-selection="updateSelectedVideo"></Videos>
    <About v-if="selection=='about'" order="1" :screenSize="getScreenSize()"></About>
    <Contact v-if="selection=='contact'" order="1" :screenSize="getScreenSize()"></Contact>
    <Footer contact="kevinnabity@kevinnabity.com" :screenSize="getScreenSize()" />
  </div>
</div>
</template>

<script>
// import axios from 'axios';
import Header from './Header.vue'
import Footer from './Footer.vue'
import Navigation from './Navigation.vue'
import Videos from './Videos.vue'
import About from './About.vue'

export default {
  name: 'KevinNabity',
  components: {
    Header,
    Navigation,
    Footer,
    Videos,
    About,
  },
  data () {
      return {
    selection: 'videos',
    selectedVideo: null,
    videoSearch: '',
    }
  },
  props: {
    msg: String
  },
  methods: {
    openLink: function (link) {
      window.open(link);
    },
    updateSelection: function (selection) {
      console.warn("UPDATING SELECTION: ", selection)
      if(selection == 'videos'){
        this.updateSelectedVideo(null)
      }
      this.selection = selection;
    },
    updateSelectedVideo: function(video){
      console.warn("Top Level New Video: ", video)
      this.selectedVideo = video
    },
    getScreenSize: function() {
      console.warn("Screen Width: ", window.innerWidth)
      return window.innerWidth;
    },
    processSelection(selection){
      if(selection == 'videos'){
        this.selectedVideo = null;
      }
      
    }
    // getRoles: function () {
    //     axios
    //     .get("/api/v1/roles")
    //     .then(response => (this.roles = response.data))
    // },
    // postRole: function () {
    // // Creating
    //     if (this.role_id == '' || this.role_id == null) {
    //         axios
    //         .post("/api/v1/roles", {
    //             "name": this.role_name,
    //         })
    //         .then(savedRole => {
    //             this.roles.push(savedRole.data);
    //             this.role_name = '';
    //             this.role_id = '';
    //         })
    //         } else 
    //         { // Updating
    //             axios
    //             .post("/api/v1/roles", {
    //                 "id": this.role_id,
    //                 "name": this.role_name,
    //             })
    //             .then(() => {
    //                 this.getRoles();
    //                 this.role_name = '';
    //                 this.role_id = '';
    //             })
    //         }
    //     },
    //     editRole: function (role) 
    //     {
    //         this.role_id = role.id;
    //         this.role_name = role.name;
    //         document.getElementById('roleForm').setAttribute("class", document.getElementById('roleForm').getAttribute("class") + " show");
    //     },
    //     deleteRole: async function (role) 
    //     {
    //         await axios
    //         .delete("/api/v1/roles/" + role.id);
    //         this.getRoles();
    //     }




  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
